<template>
  <div class="about">
    <div class="abtopdiv">
      <img @click="anchor('scrTo')"  :src="icon1" alt="">
      <i @click="showMenus=!showMenus" class="icon" :class="showMenus?'el-icon-s-fold':'el-icon-s-unfold'"></i>
    </div>
    <div class="roofdiv">
      <p class="title_p">{{language.title1}}</p>
      <p class="content_1">{{language.content}}</p>
      <img class="concetimg" :src="onlin" alt="">
      <ul class="oneUl">
        <li v-for="item in language.list1" :key="item.id">
          <img :src="require(`@/assets/images/${item.imgId}.png`)" alt="">
          <p class="p1">{{item.title}}</p>
          <hr class="hr"/>
          <p class="p2">{{item.text}}</p>
        </li>
      </ul>
    </div>
    <div class="content1">
      <div class="title" id="content1">
        <div class="title_filx">
          <p class="title_lpl"></p><p class="title_lpy"></p>
        </div>
        <p class="headline">{{language.content1.headline}}</p>
        <div class="title_filx">
          <p class="title_lpy"></p><p class="title_lpr"></p>
        </div>
      </div>
      <p class="caption">{{language.content1.caption}}</p>
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item  v-for="(item,index) in language.content1.Listmin" :key="item.id">
          <div class="indiv">
            <img class="headPortrait" :src="require(`@/assets/images/${item.imgName}.png`)" alt="">
            <h1>{{item.name}}</h1>
            <div class="inlabel">
              <h3>{{item.specialty}}</h3>
              <h3>{{item.exp}}</h3>
            </div>
            <p class="p4" >{{item.text}}</p>
            <img v-if="item.videourl" @click="videourl=item.videourl,showVideo=true" @touchend="item.icon_play='icon_play'" @touchstart="item.icon_play='icon_playred'" class="icon_play" :src="require(`@/assets/images/${item.icon_play}.png`)" alt="">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="videos" v-if="showVideo">
      <div class="vidio">
        <video :src="require(`@/assets/videos/${videourl}.mp4`)" autoplay controls></video>
        <i @click="showVideo=false" class="el-icon-error"></i>
      </div>
    </div>
    <div class="content2">
      <div class="title" id="content2">
        <div class="title_filx">
          <p class="title_lpl"></p><p class="title_lpy"></p>
        </div>
        <p class="headline" :class="{'headlineen':language.type=='EN'}">{{language.content2.headline}}</p>
        <div class="title_filx">
          <p class="title_lpy"></p><p class="title_lpr"></p>
        </div>
      </div>
      <p class="caption">{{language.content2.caption}}</p>
        <div class="Framediv bglf" >
          <div style="position: absolute;bottom: 37px;width: 100%;">
            <p class="p5">{{language.content2.List[0].title}}</p>
            <p class="p6">{{language.content2.List[0].content}}</p>
            <p class="p7">{{language.content2.List[0].buttonText}}</p>
          </div>

        </div>
        <div class="Framediv bgrt">
          <div style="position: absolute;bottom: 37px;width: 100%;">
            <p class="p5">{{language.content2.List[1].title}}</p>
            <p class="p6">{{language.content2.List[1].content}}</p>
            <p class="p7">{{language.content2.List[1].buttonText}}</p>
          </div>
        </div>
    </div>
    <div class="content3">
      <div class="title" id="content3">
        <div class="title_filx">
          <p class="title_lpl"></p><p class="title_lpy"></p>
        </div>
        <p class="headline">{{language.content3.headline}}</p>
        <div class="title_filx">
          <p class="title_lpy"></p><p class="title_lpr"></p>
        </div>
      </div>
      <p class="caption">{{language.content3.caption}}</p>
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item  v-for="(item) in language.content3.Listmin" :key="item.id">
            <img class="Portrait" :src="require(`@/assets/images/${item.imgName}.png`)" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="content4">
      <div class="title" id="content4">
        <div class="title_filx">
          <p class="title_lpl"></p><p class="title_lpy"></p>
        </div>
        <p class="headline">{{language.content4.headline}}</p>
        <div class="title_filx">
          <p class="title_lpy"></p><p class="title_lpr"></p>
        </div>
      </div>
      <p class="caption">{{language.content4.caption}}</p>
      <div>
        <img class="usmin" :src="usmin" alt="">
        <hr class="hr"/>
        <p class="p8">{{language.content4.title}}</p>
        <p class="p9">{{language.content4.text}}</p>
      </div>
    </div>
    <div class="buttom_div">
      <div class="bg_buttom">
          <div>
            <img :src="customer" alt="">
            <p class="p10">公众号</p>
          </div>
          <div>
            <img :src="PublicAccounts" alt="">
            <p class="p10">客服微信</p>
          </div>
          <p class="p11 p12">ePatch Education</p>
          <p class="p11">{{language.buttom_div.mail}}：bestnewshiya@gmail.com</p>
          <p class="p11">{{language.buttom_div.customer}}：Best-ePath</p>
          <p class="p11">{{language.buttom_div.more}}</p>
      </div>
      <p class="footnote">©ePatch Education</p>
    </div>
    <div v-if="showMenus" class="menus">
      <div style="height: 40px;">
        <van-icon @click="showMenus=!showMenus" name="cross vicon" />
      </div>
      <div>
        <p class="pbutton" :style="{background:(activeIndex=='scrTo'?'#FFE4E4':'')}" @click="onlanguage()">{{language.type=='EN'?'中文':'English'}}</p>
        <p class="pbutton" @click="anchor(item.id,item.anchor)" :style="{background:(activeIndex==item.id?'#FFE4E4':'')}" v-for="item in language.headerList" :key="item.id">{{item.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import CH from '../assets/lang/CH.json'
  import EN from '../assets/lang/EN.json'
  export default {
    name: 'Home',
    data(){
      return{
        activeIndex:"scrTo",
        icon1:require('@/assets/images/logo.png'),
        onlin:require('@/assets/images/onlin.png'),
        usmin:require('@/assets/images/usmin.png'),
        customer:require('@/assets/images/customer.png'),
        PublicAccounts:require('@/assets/images/PublicAccounts.png'),
        showVideo:false,
        showMenus:false,
      }
    },
    methods:{
      onlanguage(){
        this.activeIndex="scrTo"
        if(this.language==EN){
          sessionStorage.setItem('Internationalization','CH')
          this.language=CH
        }else {
          sessionStorage.setItem('Internationalization','EN')
          this.language=EN
        }
        window.scrollTo(0,0)
      },
      anchor(anchor,anchorId){
          this.showMenus=false
        if(anchor=="scrTo"){
          window.scrollTo(0,0)
        }else {
          document.querySelector(anchorId).scrollIntoView({
            behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
            block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
            inline: "nearest" // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
          })
          this.activeIndex = anchor
        }
      },
      onScoll(){
        let scrollTop = document.documentElement.scrollTop;
        let roofdiv=document.getElementsByClassName("roofdiv")[0].offsetTop
        let content1=document.getElementsByClassName("content1")[0].offsetTop
        let content2=document.getElementsByClassName("content2")[0].offsetTop
        let content3=document.getElementsByClassName("content3")[0].offsetTop
        let content4=document.getElementsByClassName("content4")[0].offsetTop
        this.showMenus=false
        if(scrollTop<=content1){
          this.activeIndex="0"
        }
        if(scrollTop>=content2){
          this.activeIndex="1"
        }
        if(scrollTop>=content3){
          this.activeIndex="2"
        }
        if(scrollTop>=content4){
          this.activeIndex="3"
        }
        if(scrollTop<=0){
          this.activeIndex="scrTo"
        }
      },
    },
    mounted(){
      window.addEventListener('scroll',this.onScoll,true);
    },
    destroyed () {
      window.removeEventListener('scroll', this.onScoll,true);
    },
    created() {
      if(sessionStorage.getItem('Internationalization')=='EN'){
        this.language = EN;
      }else{
        this.language = CH;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .abtopdiv{
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    background: white;
    border-bottom: 1px solid rgba(153, 153, 153, 1);
    img{
      width: 40px;
      height: 40px;
      padding: 10px;
    }
    .icon{
      font-size: 40px;
      padding: 10px;
    }
  }
  .roofdiv{
    padding:48px 24px 30px;
    box-sizing: border-box;
    margin-top: 60px;
    background: linear-gradient(69.17deg, rgba(185, 11, 11, 1) 1.14%, rgba(224, 34, 34, 1) 110.31%), rgba(255, 255, 255, 1);
    .title_p{
      color: rgba(255, 255, 255, 1);
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
    }
    .content_1{
      color: rgba(255, 255, 255, 1);
      font-family: PingFang SC;
      font-size: 12px;
      line-height: 20px;
      margin-top: 7px;
      margin-bottom: 13px;
    }
    .concetimg{
      margin-top: 7px;
      width: 100%;
    }
    .oneUl{
      margin-top: 21px;
      li{
        padding: 16px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 4px 8px 6px rgba(0, 0, 0, 0.12);
        margin-bottom: 20px;
        img{
          width: 72px;
          height: 72px;
        }
        .p1{
          color: rgba(37, 40, 66, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 24px;
          line-height: 60px;
        }
        .hr{
          width: 69px;
          height: 2px;
          background: rgba(203, 1, 1, 1);
          margin-bottom: 4px;
        }
        .p2{
          color: rgba(102, 102, 102, 1);
          font-family: PingFang SC;
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
  .title{
    text-align: center;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
    .headline{
      text-align: center;
      color: rgba(37, 40, 66, 1);
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 23px;
      line-height: 40px;
      padding: 0 12px;
    }
    .headlineen{
      font-size: 22px;
      padding: 0 10px;
    }
    .title_filx{
      display: flex;
      .title_lpl{
        width: 48px;
        height: 2px;
        background: linear-gradient(270deg, rgba(203, 1, 1, 1) 0%, rgba(203, 1, 1, 0) 100%);
        align-self: center;
      }
      .title_lpr{
        width: 48px;
        height: 2px;
        background: linear-gradient(90deg, rgba(203, 1, 1, 1) 0%, rgba(203, 1, 1, 0) 100%);
        align-self: center;
      }
      .title_lpy{
        width: 11px;
        height: 11px;
        background: rgba(203, 1, 1, 1);
        border-radius: 50%;
        align-self: center;
      }
    }
  }
  .caption{
    height: 20px;
    text-align: center;
    color: rgba(102, 102, 102, 1);
    font-family: PingFang SC;
    font-size: 11px;
    line-height: 20px;
  }
  .content1{
    margin-top: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    .my-swipe {
      .van-swipe-item {
        .indiv {
          text-align: center;
          margin-top: 22px;
          padding: 24px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.12);
          margin-bottom: 42px;
          height: 450px;
          .headPortrait {
            width: 144px;
            height: 144px;
            margin-bottom: 10px;
          }

          h1 {
            height: 60px;
            text-align: center;
            color: rgba(37, 40, 66, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 36px;
            line-height: 60px;
            margin-bottom: 6px;
          }

          .inlabel {
            display: flex;
            justify-content: center;

            h3 {
              border-radius: 4px;
              background: rgba(255, 213, 189, 1);
              padding: 5px 8px;
              height: 20px;
              text-align: center;
              color: rgba(37, 40, 66, 1);
              font-family: PingFang SC;
              font-size: 15px;
              line-height: 20px;
            }

            :last-child {
              margin-left: 8px;
            }
          }

          .p4 {
            margin-top: 25px;
            /*height: 118px;*/
            text-align: center;
            color: rgba(102, 102, 102, 1);
            font-family: PingFang SC;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 20px;
            min-height: 90px;
          }

          .p4en {
            min-height: 160px;
          }

          .icon_play {
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content2{
    margin-top: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    .Framediv{
      height: 500px;
      margin-top: 22px;
      border-radius: 8px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: PingFang SC;
      position: relative;
      .p5{
        font-weight: 500;
        font-size: 36px;
      }
      .p6{
        font-size: 16px;
        margin-bottom: 14px;
      }
      .p7{
        display: inline-block;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        background: rgba(255, 184, 184, 1);
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        margin: 0 auto;
        padding: 3px 15px;
      }
    }
    .bglf{
      background: url("../assets/images/onlinen010.png") no-repeat;
      background-size: 100% 100%;
    }
    .bgrt{
      background: url("../assets/images/onlinen020.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .content3 {
    margin-top: 32px;
    padding: 0 16px;
    box-sizing: border-box;
    .Portrait{
      width: 100%;
      border-radius: 8px;
      margin: 20px 0 40px;
    }
  }
  .content4{
    margin: 32px 0 43px;
    padding: 0 16px;
    box-sizing: border-box;
    .usmin{
      width: 100%;
      margin-top: 19px;
    }
    .hr{
      width: 69px;
      height: 2px;
      background: rgba(203, 1, 1, 1);
      margin-bottom: 4px;
    }
    .p8{
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 36px;
      line-height: 60px;
      margin-top: 28px;
    }
    .p9{
      width: 100%;
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-size: 16px;
      line-height: 28px;
      margin-top: 15px;
    }
  }
  .bg_buttom{
    background: rgba(37, 40, 66, 1);
    padding: 55px 24px 36px;
    box-sizing: border-box;
    margin: 0 auto;
      div{
        text-align: center;
        margin-bottom: 38px;
      }
      img{
        width: 160px;
        height: 160px;
      }
      .p10{
        margin-top: 12px;
        text-align: center;
        height: 20px;
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-size: 16px;
        line-height: 20px;
      }
      .p11{
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        padding-left: 14px;
        line-height: 34px;
      }
      .p12{
        font-size:24px;
        font-weight: 300;
      }
  }
  .footnote{
    height: 35px;
    color: rgba(255, 255, 255, 1);
    background: rgba(31, 34, 56, 1);
    font-family: PingFang SC;
    font-size: 12px;
    line-height: 34px;
    text-align: center;
    margin: -2px 0;
  }
  .menus{
    width: 100%;
    position: fixed;
    top: 60px;
    background: white;
    .vicon{
      position: absolute;
      top: 12px;
      right: 5px;
      z-index: 1000;
    }
    .pbutton{
      height: 35px;
      line-height: 35px;
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-size: 16px;
      padding:0 24px;
      box-sizing: border-box;
    }
  }
  .videos{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    .vidio{
      width: 100%;
      background: black;
      position: absolute;
      video{
        width: 100%;
      }
      i{
        position: absolute;
        top: 10px;
        right: 10px;
        color: aliceblue;
      }
    }

  }
  /deep/ .van-swipe__indicators {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
  }
  /deep/ .van-swipe__indicator {
    width: 15px;
    height: 8px;
    border-radius: 100px;
    background: rgb(255, 228, 228);

  }
  /deep/ .van-swipe__indicator--active {
    width: 32px;
    height: 8px;
    border-radius: 100px;
    background: rgb(203, 1, 1);
  }
</style>
